<template>
  <div id="pay">
    <div>
      <div class="head">
        <van-nav-bar
          :title="$t('personOrder.title')"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div class="outboxstyle">
      <!-- 订单详情 -->
      <div class="box-part">
        <van-cell-group>
          <van-cell title="付款金额">
            <template>
              <span class="color-red font-size-26">￥{{ fenToYuan(detailMessage.amount) }}</span>
            </template>
          </van-cell>
          <van-cell v-if="showDetailFlag" title="订单号" :value="detailMessage.orderNo" />
          <van-cell v-if="showDetailFlag" title="收费项目" :value="detailMessage.productDesc" />
          <!-- <van-cell v-if="showDetailFlag" title="项目金额" value="￥3500" /> -->
          <!-- <van-cell v-if="showDetailFlag" title="手续费" value="￥22" /> -->
          <van-cell v-if="showDetailFlag" class="cell-btn" @click="foldFlagBtn">
            <template>
              <span>收起详细信息<van-icon name="arrow-up" /></span>
            </template>
          </van-cell>
          <van-cell v-if="!showDetailFlag" class="cell-btn" @click="foldFlagBtn">
            <template>
              <span>展开详细信息<van-icon name="arrow-down" /></span>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
      <!-- 支付方式 -->
      <div class="box-part">
        <van-field v-if="detailMessage.isBillAllowed === '1'" name="switch" label="是否开票" input-align="right">
          <template #input>
            <van-switch v-model="billInfo.isOpenFlag" size="20" />
          </template>
        </van-field>
        <!-- 选择发票抬头 -->
        <van-field
          v-if="billInfo.isOpenFlag"
          readonly
          clickable
          name="picker"
          :value="billInfo.billTitleName"
          label="发票抬头"
          placeholder="点击选择"
          input-align="right"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            name="custName"
            value-key="custName"
            :columns="billInfo.billTitleList"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <!-- 选择支付方式 -->
        <van-field
          readonly
          clickable
          name="picker"
          class="select-paytype"
          :value="payTypeCheck.code"
          label="支付方式"
          input-align="right"
          @click="payTypeFlag = true"
        >
          <template #input>
            <div>
              <span v-if="!payTypeCheck.img" style="color:#D0D0D0">请选择</span>
              <img v-if="payTypeCheck.img" class="pay-type" :src="payTypeCheck.img" alt="">
            </div>
          </template>
        </van-field>
        <van-popup v-model="payTypeFlag" class="paytype-prop" round position="bottom" :style="{ height: '50%' }">
          <div class="prop-top">
            请选择支付方式
          </div>
          <div class="prop-main">
            <van-radio-group v-model="payTypeCheck.code" @change="chooseType">
              <van-radio v-for="item in payTypeList" :key="item.id" class="single-list" :name="item.code">
                <img :src="item.imageUrl">
              </van-radio>
            </van-radio-group>
          </div>
        </van-popup>
      </div>
    </div>
    <div class="bottom">
      <van-button type="info" size="large" :loading="btnLoading" loading-text="加载中..." class="bottombutton" @click="goNext">{{ $t('orderDetail.toPay') }}</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getPayType } from '@/api/waitingPay'
import { createRegistrationOrder, getWeXinJsapiTicket } from '@/api/registration/projectForRegistration'
import { getOrderById } from '@/api/orderlist'
import { fenToYuan } from '@/utils/commonUtils'
import wx from 'weixin-js-sdk'
import { getList } from '@/api/invoiceTitle'
import { toPayOrderTrade } from '@/api/registration/projectForRegistration'

export default {
  name: 'Person',
  data() {
    return {
      detailMessage: {
        // amount 付款金额
        // orderNo 订单号
        // productDesc 收费项目
      },
      billInfo: {
        isOpenFlag: false, // 是否开票
        billTitleList: [],
        billTitleId: '',
        billTitleName: ''
      },
      ip: '',
      payTypeCheck: {
        code: '',
        interfaceType: '',
        img: ''
      },
      btnLoading: false, // 按钮loading状态
      showDetailFlag: true, // 展示详细信息状态
      payTypeFlag: false, // 支付方式弹窗状态
      showPicker: false,
      payTypeList: []
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    this.getPayType()
    this.getBillRefUserList()
    this.isWeiXin()
    if (this.$route.type === 'sign') {
      createRegistrationOrder(this.$route.query.orderId).then(response => {
        this.orderAmount = response.data.amount
        this.orderNo = response.data.orderNo
        this.returnUrl = response.data.returnUrl
        this.productDesc = response.data.productDesc
      })
    } else {
      this.getOrderById()
    }
  },
  methods: {
    goNext() {
      this.btnLoading = true
      // 下行注释误删，这是声明returnCitySN方法没有定义但是有使用
      // eslint-disable-next-line no-undef
      this.ip = '218.2.101.93'
      // var order = this.$route.query.order
      var payType = this.payTypeCheck.code
      if (payType === '') {
        Toast.fail(this.$t('msg.selectMethod'))
        this.btnLoading = false
        return false
      }
      var thirdPayVO = {}
      thirdPayVO.tradeType = this.payTypeCheck.interfaceType
      if (window.localStorage.getItem('enterSource') === 'wechat') {
        if (payType === '02') {
          thirdPayVO.publicType = '2' // 服务号
          //  获取openId 方法
          if (window.localStorage.getItem('openId') !== undefined && !window.localStorage.getItem('openId') !== '') {
            thirdPayVO.openId = window.localStorage.getItem('openId')
          }

          // thirdPayVO.subAppId = window.localStorage.getItem('subAppId')
          // thirdPayVO.appId = window.localStorage.getItem('appId')
        }
        // 仙林学校，手机端支付宝支付方式使用的是native形式  enterSource=NATIVE目前只为支付宝服务
      } else if (window.localStorage.getItem('enterSource') === 'CoWechat') {
        thirdPayVO.publicType = '1' // 服务号
        //  获取openId 方法
        if (window.localStorage.getItem('openId') !== undefined && !window.localStorage.getItem('openId') !== '') {
          thirdPayVO.openId = window.localStorage.getItem('openId')
        }
      } else if (window.localStorage.getItem('enterSource') === 'NATIVE' && payType === '01') {
        thirdPayVO.tradeType = 'NATIVE'
      } else if (window.localStorage.getItem('enterSource') === 'wechat' && payType === '39') {
        thirdPayVO.tradeType = 'JSAPI'
        thirdPayVO.openId = window.localStorage.getItem('openId')
      }
      if (window.localStorage.getItem('enterSource') === 'wechat' && payType === '72') { // 微信进用工行协议支付tradeType还是WEB
        thirdPayVO.tradeType = 'WEB'
      }
      thirdPayVO.payType = payType
      // thirdPayVO.tradeType = this.tradeType
      thirdPayVO.orderNo = this.detailMessage.orderNo
      thirdPayVO.ip = this.ip
      thirdPayVO.schoolCode = window.localStorage.getItem('schoolcode')
      thirdPayVO.dataSource = this.$store.state.settings.dataSource
      thirdPayVO.returnUrl = this.detailMessage.returnUrl
      if (this.billInfo.isOpenFlag) {
        thirdPayVO.billPersonInfoId = this.billInfo.billTitleId
      }
      // thirdPayVO.openId = 'oEFKY505Kk7nUBNi9vgF7uPBEbYg'
      toPayOrderTrade(thirdPayVO).then(response => {
        // var enterSouce = window.localStorage.getItem('enterSource')
        // 微信单独处理
        // alert(response.data)
        console.log(response.data)
        if (response.data.payType === '02') {
          if (response.data.tradeType === 'JSAPI' && window.localStorage.getItem('enterSource') === 'wechat') {
            this.callWxPay(response.data)
          } else if (response.data.tradeType === 'JSAPI' && window.localStorage.getItem('enterSource') === 'CoWechat') {
            console.log('微信开始拉起企业微信jsapi支付')
            var agentid = ''
            // 如果是企业微信jsapi支付， 则传入agentid
            if (thirdPayVO.publicType === '1') {
              agentid = window.localStorage.getItem('agentId')
              if (window.localStorage.getItem('schoolcode') === '12598KF000001') {
                agentid = '1000037'
              }
            }
            getWeXinJsapiTicket(response.data.appid, agentid, location.href.split('#')[0]).then(resp => {
              wx.config({
                debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: response.data.appid, // 必填，公众号的唯一标识
                timestamp: resp.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: resp.data.noncestr, // 必填，生成签名的随机串
                signature: resp.data.sign, // 必填，签名，见附录1
                jsApiList: ['getBrandWCPayRequest', 'chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              })
              this.jsApiCoCall(response.data)
            })
          } else {
            window.location = response.data.mwebUrl
          }
          console.log(response.data, '微信返回')

          // window.location = response.data.mwebUrl
          this.$router.push({ path: '/paySuccess', query: { returnUrl: this.returnUrl, orderId: this.$route.query.orderId }})
          // payTypeName = '微信'
        } else if (response.data.payType === '57') {
          this.$router.push({ path: '/paySuccess', query: { returnUrl: this.returnUrl, orderId: this.$route.query.orderId }})
        } else if (response.data.payType === '80') {
          console.log('财政非税统缴')
          console.log(response.data, '财政非税统缴')
          window.location = response.data.urlCode
          // payTypeName = '微信'
        } else if (response.data.payType === '14') {
          console.log('农行网银')
          console.log(response.data, '农行网银')
          window.location.href = response.data.webUrl
          // payTypeName = '微信'
        } else if (response.data.payType === '39') {
          if (response.data.tradeType === 'JSAPI') {
            this.cibWxPay(response.data)
          } else {
            window.location = response.data.mwebUrl
          }
          console.log(response.data, '兴业微信返回')
          this.$router.push({ path: '/paySuccess', query: { returnUrl: this.returnUrl, orderId: this.$route.query.orderId }})
        } else if (response.data.payType === '64') {
          console.log('紫金农商行网银')
          console.log(response.data, '紫金农商行网银')
          window.location.href = response.data.webUrl
          // payTypeName = '微信'
        } else if (response.data.payType === '65') {
          console.log('小浦支付')
          console.log(response.data, '小浦支付')
          document.querySelector('body').innerHTML = response.data.sbHtml // 查找到当前页面的body，将后台返回的form替换掉他的内容
          document.forms[0].submit()
          // payTypeName = '微信'
        } else if (response.data.payType === '26') {
          console.log('工行jsapi支付')
          document.querySelector('body').innerHTML = response.data.sbHtml // 查找到当前页面的body，将后台返回的form替换掉他的内容
          document.forms[0].submit()
          // payTypeName = '微信'
        } else if (response.data.payType === '66') {
          window.location = response.data.webUrl
        } else if (response.data.payType === '69') {
          console.log('兴业银行收付直通车支付')
          console.log(response.data, '兴业银行收付直通车支付')
          document.querySelector('body').innerHTML = response.data.sbHtml // 查找到当前页面的body，将后台返回的form替换掉他的内容
          document.forms[0].submit()
        // 建行网银WAP支付
        } else if (response.data.payType === '08') {
          document.querySelector('body').innerHTML = response.data.sbHtml // 查找到当前页面的body，将后台返回的form替换掉他的内容
          document.forms[0].submit()
        } else if (response.data.payType === '72') { // 工行协议支付
          this.$router.push({ path: '/paySuccess', query: { orderId: this.$route.query.orderId }})
        } else if (response.data.payType === '75') { // 银联商务支付
          window.location = response.data.urlCode
        } else {
          // 使用form表单提前都走这里
          // payTypeName = '支付宝/银联;'  仙林定制
          if (response.data.tradeType === 'WAP' && response.data.payType === '01') {
            // console.log('response.data.sbHtml')
            // console.log(response.data.sbHtml)
            document.querySelector('body').innerHTML = response.data.sbHtml // 查找到当前页面的body，将后台返回的form替换掉他的内容
            document.forms[0].submit()
          } else if (response.data.tradeType === 'NATIVE') {
            window.location.href = response.data.urlCode
          } else {
            Toast.fail('交易渠道获取失败')
          }
        }
        // this.$router.push({ name: 'payQrcode', query: { amount: fenToYuan(this.orderAmount), payType: payTypeName, qrcodeUrl: response.data.urlCode, orderId: this.$route.query.orderId }})
      }).catch(() => {
        this.btnLoading = false
      })
    },
    // 判断企业微信/微信浏览器
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase()
      if (ua.indexOf('micromessenger') !== -1 && ua.indexOf('wxwork') !== -1) {
        window.localStorage.setItem('enterSource', 'CoWechat')
      } else if (ua.indexOf('micromessenger') !== -1) {
        window.localStorage.setItem('enterSource', 'wechat')
      }
    },
    getBillRefUserList() {
      var userId = this.$store.getters.userId
      getList(userId).then(res => {
        this.billInfo.billTitleList = res.data
        // 如果有默认自动赋值
        res.data.forEach(t => {
          if (t.isDefault === '1') {
            this.billInfo.billTitleId = t.id
            this.billInfo.billTitleName = t.custName
            return
          }
        })
      })
    },
    fenToYuan(val) {
      return fenToYuan(val)
    },
    getOrderById() {
      getOrderById(this.$route.query.orderId).then(response => {
        this.detailMessage = response.data
        this.getPayType()
      })
    },
    getPayType() {
      var enterType = ''
      if (window.localStorage.enterSource !== undefined && window.localStorage.enterSource !== 'undefined' && window.localStorage.enterSource !== 'null' && window.localStorage.enterSource !== null) {
        enterType = window.localStorage.enterSource
      } else {
        enterType = 'H5'
      }
      getPayType(this.$route.query.projectId, enterType).then(res => {
        this.payTypeList = res.data
        // 如果支付方式之前选择过 过滤掉其他支付方式
        if (this.detailMessage.tradeChannel) {
          var end = this.payTypeList.find(item => {
            return item.code === this.detailMessage.tradeChannel
          })
          this.payTypeList = []
          this.payTypeList.push(end)
        }
      })
    },
    chooseType() {
      for (let index = 0; index < this.payTypeList.length; index++) {
        if (this.payTypeList[index].code === this.payTypeCheck.code) {
          this.payTypeCheck.img = this.payTypeList[index].imageUrl
          this.payTypeCheck.interfaceType = this.payTypeList[index].interfaceType
          this.payTypeFlag = false
          return false
        }
      }
    },
    onConfirm(value) {
      this.billInfo.billTitleName = value.custName
      this.billInfo.billTitleId = value.id
      this.showPicker = false
    },
    // 折叠详细信息按钮
    foldFlagBtn() {
      this.showDetailFlag = !this.showDetailFlag
    },
    // 调用微信JS api 支付
    jsApiCall(data) {
      window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        'appId': data.appid,
        'timeStamp': data.timestamp,
        'nonceStr': data.noncestr,
        'package': data.wxpackage,
        'signType': 'MD5',
        'paySign': data.sign
      },
      function(res) {
        window.WeixinJSBridge.log(res.err_msg)
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          this.$router.push({ path: '/home' })
        } else {
          // 显示错误信息
          this.$toasted.show(res.err_code + res.err_desc + res.err_msg, {
            position: 'top-center',
            duration: 3000
          })
        }
        this.$router.push({ path: '/home' })
      })
    },
    jsApiCoCall(data) {
      wx.ready(function() {
        wx.chooseWXPay({
          timestamp: data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: data.noncestr, // 支付签名随机串，不长于 32 位
          package: data.wxpackage, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
          signType: 'MD5', // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: data.sign, // 支付签名
          success: function(res) {
            // 支付成功后的回调函数
            // resolve(res)
            this.$router.push({ path: '/paySuccess', query: { returnUrl: this.returnUrl, orderId: this.$route.query.orderId }})
          },
          cancel: function(res) {
            // 已取消支付
            console.log(res)
          },
          fail: function(res) {
            console.log(res)
            // 失败回调函数
          }
        })
      })
    },
    callWxPay(params) {
      if (typeof window.WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.jsApiCall(params), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.jsApiCall(params))
          document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall(params))
        }
      } else {
        this.jsApiCall(params)
      }
    },
    cibWxPay(data) {
      window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        'appId': data.appid,
        'timeStamp': data.timestamp,
        'nonceStr': data.noncestr,
        'package': data.wxpackage,
        'signType': 'RSA',
        'paySign': data.sign
      },
      function(res) {
        window.WeixinJSBridge.log(res.err_msg)
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          this.$router.push({ path: '/home' })
        } else {
          // 显示错误信息
          this.$toasted.show(res.err_code + res.err_desc + res.err_msg, {
            position: 'top-center',
            duration: 3000
          })
        }
        this.$router.push({ path: '/home' })
      })
    },
    // 返回
    onClickLeft() {
      // this.$router.go(-1)
      this.$router.go(-1)
    }
  }
}
</script>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
</style>
<style scoped lang="scss">
#pay {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 86px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 12px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.bottombutton{
  margin: 10px 20px;
  width:90%;
  height: 40px;
}
.group {
  height:60px;
  border:1px solid #3BA4F2;
  border-radius:6px;
  position: relative;
}
.group2 {
  height:60px;
  border:1px solid #eee;
  border-radius:6px;
  position: relative;
}
.icon{
  width: 22px;
  filter: drop-shadow(#2F9FF2 100px 0);
  transform: translateX(-100px);
}
// new person
.color-red{
  color: #D84521;
}
.font-size-26{
  font-size: 26px;
}
.box-part{
  background-color: #fff;
  border-radius: 10px;
  font-size: 12px;
  padding: 5px 15px;
  margin-bottom: 10px;
}
.van-cell:not(:last-child)::after{
  left: 0;
}
.van-cell{
  padding-left: 0;
  padding-right: 0;
  .van-cell__title{
    font-size: 12px;
    color: #747474;
  }
  .van-cell__value{
    font-size: 14px;
    color: #2e2e2e;
  }
}
.cell-btn{
  .van-cell__value{
    display: flex;
    justify-content: center;
    color: #D0D0D0;
    font-size: 14px;
  }
}
.pay-type{
  height: 30px;
}
.select-paytype:not(:last-child)::after{
  display: none;
}
.paytype-prop{
  .prop-top{
    height: 50px;
    background-color: #F5F5F5;
    text-align: center;
    line-height: 50px;
    color: #979797;
    font-size: 14px;
  }
  .prop-main{
    padding: 0 24px;
    .single-list{
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 15px 0;
      border-bottom: 1px dashed #F5F5F5;
      img{
        height: 30px;
      }
    }
  }
}
</style>
